import React from 'react';
import './App.css';
import config from "./config";
import MicrosoftLogin from "react-microsoft-login";
import axios from 'axios';
import mergeImages from 'merge-images';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';


class App extends React.Component {

  constructor(props) {
    super(props);
    this.login = React.createRef();
    this.slider = React.createRef();
    this.state = {
      token: undefined,
	  x: 0,
	  y: 0,
	  dragStartX: 0,
	  dragStartY: 0,
      registered: false
    }
    this.updatePhoto = this.updatePhoto.bind(this);
	this.dragStart = this.dragStart.bind(this);
	this.drag = this.drag.bind(this);
	this.dragOver = this.dragOver.bind(this);
	this.dragEnd = this.dragEnd.bind(this);
  }

  updateImage(filterImage, xOffset, yOffset, target) {
    mergeImages([{ src: this.originalImg.src, x: xOffset, y: yOffset }, { src: filterImage }], { format: 'image/jpeg' })
    .then(b64 => target.src = b64);

  }

  componentDidMount() {
    this.dragImg = new Image(0,0);
    this.dragImg.src =
    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
	
  }

  componentDidUpdate() {
    if (this.state.token) {
      //let url = 'https://graph.microsoft.com/beta/users/timothee.vallin@ihsmarkit.com/photo/$value'
      let url = 'https://graph.microsoft.com/beta/me/photo/$value';
      axios.get(url,
        {
          headers: {
            'Authorization': 'Bearer ' + this.state.token,
            'Content-Type': 'image/jpg'
          },
          responseType: 'arraybuffer'
        }).then((res) => {

          let base64 = Buffer.from(res.data, 'binary').toString('base64')
          this.originalImg.src = "data:image/jpg;base64," + base64;
          let tmpImage = new Image();
          let that = this;
          tmpImage.onload = function () {
            let targetWidth = this.width;
            let targetHeight = this.height;

            let progressImg = new Image();
            progressImg.onload = function () {
              let canvas = document.createElement('canvas');
              let ctx = canvas.getContext('2d');

              // set its dimension to target size
              canvas.width = targetWidth;
              canvas.height = targetHeight;

              // draw source image into the off-screen canvas:
              ctx.drawImage(this, 0, 0, targetWidth, targetHeight);

              // encode image to data-uri with base64 version of compressed image
              that.resizedProgress = canvas.toDataURL();
              that.updateImage(that.resizedProgress, 0, 0, that.progressImg);

            }
            progressImg.src = './pride_avatar.png';
          }
          tmpImage.src = this.originalImg.src;

        });
    }
  }
  
  dragStart = (event) => {
	 event.dataTransfer.effectAllowed = "copyMove";
	 event.dataTransfer.setDragImage(this.dragImg, 0, 0);
	 this.state.dragStartX = event.clientX;
	 this.state.dragStartY = event.clientY;
	 console.log(event.clientX, event.clientY);	 
  }

  drag = (event) => {
	 this.state.x += event.clientX - this.state.dragStartX;
	 this.state.y += event.clientY - this.state.dragStartY;
	 this.updateImage(this.resizedProgress, this.state.x, this.state.y, this.progressImg);
	 this.state.dragStartX = event.clientX;
	 this.state.dragStartY = event.clientY;
	 console.log(event.clientX, event.clientY);	 
  }
  
  dragOver = (event) => {
     event.dataTransfer.dropEffect = "move";
	 event.preventDefault();
  }
  
  dragEnd = (event) => {
	 this.state.x += event.clientX - this.state.dragStartX;
	 this.state.y += event.clientY - this.state.dragStartY;
	 this.updateImage(this.resizedProgress, this.state.x, this.state.y, this.progressImg);
	 console.log(event.clientX, event.clientY);	 
  }

  updatePhoto() {
    this.downloadBase64File(this.originalImg, 'me.jpg');
    this.downloadBase64File(this.progressImg, 'progress_me.jpg');
    axios.get('/photo_downloaded.txt').then((res) => {
            console.log(res);
        });
  }

  downloadBase64File(image, fileName) {

    if (window.navigator && window.navigator.msSaveBlob) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, image.width, image.height);
      window.navigator.msSaveBlob(canvas.msToBlob(), fileName);

    }
    else {
      const downloadLink = document.createElement('a');
      document.body.appendChild(downloadLink);
      downloadLink.href = image.src;
      downloadLink.target = '_self';
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }

  b64toBlob(b64Data, contentType = 'image/jpeg', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  signUp() {
    axios({method: 'post', url: '/becomeAnAlly/', 
    headers: {'Content-Type': 'application/json'}, data: {
      email: this.state.email
  }}).then( (res) => {
    const newState = {
      token: this.state.token,
      registered: true,
      email: this.state.email,
      displayName: this.state.displayName
    }
    this.setState(newState);
  });
  }

  render() {
    /*
  
    */
    const authHandler = (err, data) => {
      let url2 = 'https://graph.microsoft.com/beta/me';
      axios.get(url2,
        { headers: { 'Authorization': 'Bearer ' + data.accessToken } }).then((res) => {
          this.setState({ token: data.accessToken, email: res.data.mail, displayName: res.data.displayName, registered: false });
        });
    };
    if (this.state.token) {
      return (
        <div>
          <div class="logo2"><img src="/images/IHS-Markit-logo.png" width="145px" align="right" /></div>
          <div class="logo1"> <img src="/images/IHSMarkitPride.png" width="580px" align="left" /></div>
          <div class="becoming1">
            <img src="/banner_flag.jpg" width="50%" />
          </div>
          <div className="App">
          <p>
            June is traditionally Pride Month, show your support for your LGBT+ friends and colleagues by
            changing your Teams Photo for Pride Month. The Rainbow Flag with the chevrons is maybe less familiar to you. This is the Progress Flag. It is an update
            to the Rainbow Flag to include the trans* flag colours and also includes black and brown to represent the BAME community. It is a reminder that
            many of the rights we take for granted in some parts of the world are thanks to pioneers from these communities, and that in many places rights are
            still being fought for. We encourage you to use the Progress Flag version. 
        </p>
        </div>
          <div className="App">
            <img src={""} alt={"Loading..."} ref={(e) => this.originalImg = e} />
            <img class="adjustable" draggable="true" onDragOver={(event)=>this.dragOver(event)} onDragStart={(event)=>this.dragStart(event)} onDrag={(event)=>this.drag(event)} onDragEnd={(event)=>this.dragEnd(event)} src={""} alt={"Loading..."} ref={(e) => this.progressImg = e} />
          </div>
          <div className="App">
			<p>
			You can centre your face inside the circle by clicking and dragging.
			</p>
		  </div>
		  <div className="App">
            <button className="button w-button accept" onClick={this.updatePhoto}>Click here to download my photos</button>
          </div>
          <div className="App">
            To update your photo, open Teams and click on your current photo displayed top right. Now click the "Change Picture" link below your name.
          </div>
          <div className="App">
            The Progress Flag was designed by Dan Quaser and is Licensed Under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/">CC 4.0</a>
          </div>
          <div class="pride"><span>Pride@IHS Markit: the LGBT+ network for colleagues of all gender identities and sexual
            orientations</span></div>
        </div>

      );
    }
    else {
      return (
        <div className="App">
          <div class="logo2"><img src="/images/IHS-Markit-logo.png" width="145px" align="right" /></div>
          <div class="logo1"> <img src="/images/IHSMarkitPride.png" width="580px" align="left" /></div>
          <br />
          <div className="becoming1">
            Please login to continue
          </div>

          <div className="becoming1">
            <MicrosoftLogin ref={this.login} clientId={config.client_id} authCallback={authHandler} graphScopes={['User.Read']} />
          </div>
        </div>
      );
    }

  }
}

export default App;
